<template>
    <div class="login">
        <!-- <div><img src="../../assets/img/backimg.jpg"/></div> -->
        <div class="login-center">
            <div class="login-form">
                <div class="login-title-tabs">
                    <div class="li-tab" v-for="(item,index) in tabsParam" @click="toggleTabs(index)" :class="{active:index == nowIndex}" :key="index">{{item}}</div>
                </div>
                <div class="divTab" v-show="nowIndex === 0">
                    <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="0" class="demo-ruleForm">
                        <el-form-item prop="phone">
                            <el-input v-model="ruleForm.phone" auto-complete="off" type="number" placeholder="请输入您的账号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input type="password" v-model="ruleForm.password" auto-complete="off" placeholder="请输入您的密码"></el-input>
                        </el-form-item>
                        <el-form-item style="margin-bottom:0;">
                            <el-button ype="primary" @click="submitForm('ruleForm')" style="width:100%;" class="login_button">登 录</el-button>
                        </el-form-item>
                    </el-form>
                    <div class="login-bottom">
                        <p @click="goPassword">忘记密码</p>
                        <p @click="goRegister">注册账号</p>
                    </div>
                </div>
                <div class="divTab" v-show="nowIndex === 1">
                    <div class="divTab-center">
                        <div class="div-code"><img src="../../assets/img/login-code.png" alt=""></div>
                        <p>请用微信扫描二维码登录</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { accountLogin } from "../../api/login";
export default {
    data() {
        // <!--验证手机号是否合法-->
        let checkTel = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号码或企业账号"));
            } else {
                callback();
            }
        };
        // <!--验证密码-->
        let validatePass = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else {
                if (this.ruleForm.checkPass !== "") {
                    this.$refs.ruleForm.validateField("checkPass");
                }
                callback();
            }
        };
        return {
            // tabsParam: ['账号登陆', '扫码登陆'],
            tabsParam: ["登录"],
            nowIndex: 0,
            ruleForm: {
                phone: "",
                password: "",
            },
            rules: {
                phone: [{ validator: checkTel, trigger: "change" }],
                password: [{ validator: validatePass, trigger: "change" }],
            },
        };
    },
    methods: {
        // tab页
        toggleTabs: function (index) {
            this.nowIndex = index;
        },

        // 登录
        submitForm(formName) {
            this.$refs.ruleForm.validate((valid) => {
                if (valid) {
                    accountLogin({
                        username: this.ruleForm.phone,
                        password: this.ruleForm.password,
                    }).then((res) => {
                        if (res.data.code == 20000) {
                            // this.$router.push("/");
                            // console.log(this.$route);
                            this.$message.success(res.data.message);
                            // 存储token
                            this.$store.commit(
                                "set_token",
                                res.data.data.token
                            );
                            localStorage.setItem(
                                "phone",
                                JSON.stringify(this.ruleForm.phone)
                            );
                            localStorage.setItem(
                                "account",
                                JSON.stringify(res.data.data.company.account)
                            );
                            localStorage.setItem(
                                "name",
                                JSON.stringify(res.data.data.company.name)
                            );
                            localStorage.setItem(
                                "status",
                                JSON.stringify(res.data.data.company.status)
                            );
                            localStorage.setItem(
                                "security_phone",
                                JSON.stringify(res.data.data.company.phone)
                            );
                            // this.$router.push(this.$route.query.name || "/");
                            this.$router.push(this.$route.query.name || "/");
                        } else {
                            this.$message.error(res.data.message);
                        }
                    });
                }
            });
        },

        // 验证手机号
        checkMobile(str) {
            // let re = /^1\d{10}$/;
            // if (re.test(str)) {
            //     return true;
            // } else {
            //     return false;
            // }
        },

        goRegister() {
            this.$router.push("/register");
        },

        goPassword() {
            this.$router.push("/password");
        },
    },
};
</script>

<style lang="scss" scoped>
.login {
    .login-center {
        width: 100%;
        height: 100vh;
        background-image: url("../../assets/img/bg.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
        .login-form {
            .login-title-tabs {
                width: 100%;
                height: 60px;
                background-color: #f8f8f8;
                display: flex;
                align-items: center;
                justify-content: center;
                // justify-content: space-between;
                .li-tab {
                    width: 50%;
                    height: 100%;
                    color: #333333;
                    font-size: 16px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;
                }
                .active {
                    // border-bottom:2px solid #123E90;
                    // color:#123E90;
                }
            }
            .divTab {
                padding: 40px 45px 0 45px;
                box-sizing: border-box;
                .login-bottom {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    p {
                        color: #123e90;
                        font-size: 16px;
                        cursor: pointer;
                    }
                }
                .divTab-center {
                    width: 100%;
                    .div-code {
                        width: 210px;
                        height: 210px;
                        border: 1px solid #e2e2e2;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        margin: auto;
                        img {
                            width: 200px;
                            height: 200px;
                        }
                    }
                    p {
                        color: #333333;
                        font-size: 14px;
                        margin-top: 14px;
                        text-align: center;
                    }
                }
            }
        }
    }
}
</style>